import { Geolocation } from '@capacitor/geolocation';
import { mande } from 'mande';
import { defineStore } from 'pinia';

const api = mande(`${import.meta.env.VITE_APP_API_URL}/assets`);

const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  // Convert km to miles
  return d * 0.621371;
};

export const useStore = defineStore('asset', {
  // arrow function recommended for full type inference
  state: (): { loading: boolean; assets: ETNAsset[]; location: { lat?: number; lng?: number } } => {
    return {
      assets: [],
      location: {},
      loading: false
    };
  },
  actions: {
    async getCurrentLocation() {
      // Populate asset with distance from user
      const pos = await Geolocation.getCurrentPosition();

      this.location = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      };
    },
    async listAssets() {
      try {
        this.loading = true;

        await this.getCurrentLocation();

        const response = (await api.get({
          query: {
            $limit: 1000000,
            $select: 'siteName,address'
          }
        })) as ETNAPIResponse;

        const assets = response.data as ETNAsset[];

        const assetsWithDistance = assets.map((a: ETNAsset) => {
          if (!this.location.lat || !this.location.lng) {
            return {
              ...a,
              distance: 999999
            };
          }

          const distance = getDistance(
            this.location.lat,
            this.location.lng,
            a.address.latitude,
            a.address.longitude
          );

          return {
            ...a,
            distance
          };
        });

        // Sort by distance, if they have same distance sort by siteName
        assetsWithDistance.sort((a: ETNAsset, b: ETNAsset) => {
          if (!a.distance || !b.distance || a.distance === b.distance) {
            return a.siteName.localeCompare(b.siteName);
          }

          return a.distance - b.distance;
        });

        this.assets = assetsWithDistance.map((a: ETNAsset) => {
          if (a.distance === 999999) {
            return {
              ...a,
              distance: null
            };
          }

          return a;
        });

        this.loading = false;
        return this.assets;
      } catch (e) {
        this.loading = false;
        return e;
      }
    }
  },
  getters: {
    getAssetById:
      (state) =>
      (assetId: string): ETNAsset | undefined => {
        return state.assets.find((asset) => asset._id === assetId);
      }
  }
});
