import { defineStore } from 'pinia';
import { mande } from 'mande';

const api = mande(`${import.meta.env.VITE_APP_API_URL}/readings`);

export const useStore = defineStore('reading', {
  state: (): { loading: boolean; readings: ETNReading[] } => {
    return {
      readings: [],
      loading: false
    };
  },
  actions: {
    async listReadings(accountId: string) {
      try {
        this.loading = true;

        const response = (await api.get({
          query: {
            accountId,
            $limit: 10,
            $sort: 'submittedAt:-1',
            $select: 'value,submittedAt,type,isReset,registerId'
          }
        })) as ETNAPIResponse;

        const readings = (response.data as ETNReading[]).map((reading) => {
          const [year, month, day] = reading.submittedAt.slice(0, 10).split('-');
          const [hour, min, sec] = reading.submittedAt.slice(11, 19).split(':');
          reading.submittedAt = `${day}/${month}/${year} ${hour}:${min}:${sec}`;
          return reading;
        });

        this.readings = readings;

        this.loading = false;
        return this.readings;
      } catch (e) {
        this.loading = false;
        return e;
      }
    },
    async submitReading(reading: Omit<ETNReading, '_id'>) {
      await api.post('submit', reading);
    },
    async getPhotoUploadUrl(format: string): Promise<{ uploadUrl: string; s3Key: string } | null> {
      try {
        const response = await api.get('/photo-upload-url', {
          query: {
            contentType: `image/${format}`
          }
        });

        return response as { uploadUrl: string; s3Key: string };
      } catch (e) {
        return null;
      }
    }
  }
});
