import { authGuard } from '@auth0/auth0-vue';
import { createRouter, createWebHistory } from 'vue-router';

import ReadingView from '@/views/ReadingView.vue';
import SelectAccount from '@/components/SubmitAccount.vue';
import SubmitReading from '@/components/SubmitReading.vue';
import SubmitPhoto from '@/components/SubmitPhoto.vue';
import SubmitConfirmation from '@/components/SubmitConfirmation.vue';
import ReadingsQueue from '@/components/ReadingsQueue.vue';
import ReadingsQueueItem from '@/components/ReadingsItem.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: '',
      redirect: '/select',
      component: ReadingView,
      beforeEnter: authGuard,
      children: [
        {
          path: 'select',
          name: 'select',
          component: SelectAccount
        },
        {
          path: 'reading',
          name: 'reading',
          component: SubmitReading
        },
        {
          path: 'photo',
          name: 'photo',
          component: SubmitPhoto
        },
        {
          path: 'confirmation',
          name: 'confirmation',
          component: SubmitConfirmation
        },
        {
          path: 'queue',
          name: 'queue',
          component: ReadingsQueue
        },
        {
          path: 'queue/:id',
          name: 'queue-item',
          component: ReadingsQueueItem
        }
      ]
    }
  ]
});

export default router;
