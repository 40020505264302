import { defineStore } from 'pinia';
import { mande } from 'mande';

const api = mande(`${import.meta.env.VITE_APP_API_URL}/accounts`);

export const useStore = defineStore('account', {
  state: (): { loading: boolean; accounts: ETNAccount[] } => {
    return {
      accounts: [],
      loading: false
    };
  },
  actions: {
    async listAccounts() {
      try {
        this.loading = true;

        const response = (await api.get({
          query: {
            type: 'electricity,gas,water,heating,cooling',
            $multi: true,
            $limit: 1000000,
            $select:
              'meterSerialNumber,meterPointNumber,name,type,location,parentAccountId,registerIds,assetId,entityId,companyId,meterUnits,factor'
          }
        })) as ETNAPIResponse;

        const accounts = response.data as ETNAccount[];

        this.accounts = accounts;

        this.loading = false;
        return this.accounts;
      } catch (e) {
        this.loading = false;
        return e;
      }
    }
  },
  getters: {
    getAccountById:
      (state) =>
      (accountId: string): ETNAccount | undefined => {
        return state.accounts.find((account) => account._id === accountId);
      },
    accountsByAssetId:
      (state) =>
      (assetId: string): ETNAccount[] => {
        return state.accounts.filter((account) => account.assetId === assetId);
      }
  }
});
