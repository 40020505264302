import { defineStore } from 'pinia'
import { mande } from 'mande'

const api = mande(`${import.meta.env.VITE_APP_API_URL}/companies`)

interface ETNCompany {
  name: string
}

export const useStore = defineStore('company', {
  // arrow function recommended for full type inference
  state: (): { company: ETNCompany } => {
    return {
      company: {
        name: 'N/A',
      },
    }
  },
  actions: {
    async getCompany(id: string) {
      try {
        this.company = await api.get(id)
      } catch (e) {
        return e
      }
    },
  },
})
