import { defaults } from 'mande'
import { defineStore } from 'pinia'

export const useStore = defineStore('auth', {
  state: () => ({
    token: '',
    user: {},
  }),
  actions: {
    setToken(token: string) {
      defaults.headers.Authorization = `Bearer ${token}`
      this.token = token
    },
  },
})
